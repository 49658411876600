<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div class="mt-5">
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <p class="text-title mon-bold">
                  {{ texts.disputes.textTitle }}
                </p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "DisputesSearchbarLayout",
  data() {
    return {
      //VARIABLES
    };
  },
  beforeMount() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>


<style scoped>
.text-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}
</style>